import { Container } from '@mui/material';
import Footer from 'components/footer';
import TopNav from 'components/top-nav';
import { white } from 'theme/Theme';

import SmartLinkFAQsSection from './SmartLinkFAQsSection';
import SmartLinkHeroSection from './SmartLinkHeroSection';
import SmartLinkInfoSection from './SmartLinkInfoSection';

export const SmartLinkPage = () => {
  return (
    <Container disableGutters maxWidth={false} sx={{ background: white, position: 'relative' }}>
      <TopNav activeTab='Products' />
      <SmartLinkHeroSection />
      <SmartLinkInfoSection />
      <SmartLinkFAQsSection />
      <Footer />
    </Container>
  );
};
