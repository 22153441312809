const ConnectWalletStepperContainer = lazy(
  () => import('components/connect-wallet-stepper/ConnectWalletStepperContainer'),
);
import Grid from '@mui/material/Grid';
import NotistackSnackbarProviderWrapper from 'components/snackbar/NotistackSnackbarProviderWrapper';
import { useFeatureFlag } from 'contexts/FeatureFlagsContext';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EasySubscriptionPage } from 'views/products/EasySubscribe';
import { SmartLinkPage } from 'views/products/SmartLink';
import RedirectPage from 'views/redirect';

import ScrollToTop from './ScrollToTop';

const AuthContextProvider = lazy(() =>
  import('../contexts/AuthContextProvider').then(module => ({
    default: module.AuthContextProvider,
  })),
);

const RequireAuth = lazy(() => import('./RequireAuth'));

const WalletLogin = lazy(() =>
  import('views/login/WalletLogin').then(module => ({
    default: module.WalletLogin,
  })),
);

const MobileStore = lazy(() => import('views/marketing/MobileStore'));
const DiscordVerificationPage = lazy(() =>
  import('views/signup/DiscordVerificationPage').then(module => ({
    default: module.DiscordVerificationPage,
  })),
);

const SlackVerificationPage = lazy(() =>
  import('views/signup/SlackVerificationPage').then(module => ({
    default: module.SlackVerificationPage,
  })),
);

const TelegramVerificationPage = lazy(() =>
  import('views/signup/TelegramVerificationPage').then(module => ({
    default: module.TelegramVerificationPage,
  })),
);

const DashboardView = lazy(() =>
  import('views/dashboard').then(module => ({
    default: module.DashboardView,
  })),
);

const ChatView = lazy(() =>
  import('views/chat').then(module => ({
    default: module.ChatView,
  })),
);
const DappSubscriptionsView = lazy(() =>
  import('views/dappsubscriptions').then(module => ({
    default: module.DappSubscriptionsView,
  })),
);
const LogoutView = lazy(() =>
  import('views/logout').then(module => ({
    default: module.LogoutView,
  })),
);
const SettingsView = lazy(() =>
  import('views/settings').then(module => ({
    default: module.SettingsView,
  })),
);
const CreatorView = lazy(() => import('views/creator'));
const LandingPage = lazy(() =>
  import('views/marketing/LandingPage/LandingPage').then(module => ({
    default: module.LandingPage,
  })),
);
const FAQsPage = lazy(() =>
  import('views/marketing/FAQsPage').then(module => ({
    default: module.FAQsPage,
  })),
);
const HubPage = lazy(() =>
  import('views/marketing/HubPage').then(module => ({
    default: module.HubPage,
  })),
);
const IntegrationsPage = lazy(() =>
  import('views/marketing/IntegrationsPage').then(module => ({
    default: module.IntegrationsPage,
  })),
);
const PressKitPage = lazy(() =>
  import('views/marketing/PressKitPage').then(module => ({
    default: module.PressKitPage,
  })),
);
const PrivacyPage = lazy(() =>
  import('views/marketing/PrivacyPage').then(module => ({
    default: module.PrivacyPage,
  })),
);
const TermsPage = lazy(() =>
  import('views/marketing/TermsPage').then(module => ({
    default: module.TermsPage,
  })),
);
const EcosystemsPage = lazy(() =>
  import('views/marketing/EcoSystemsPage').then(module => ({
    default: module.EcosystemsPage,
  })),
);
const TeamPage = lazy(() =>
  import('views/marketing/Teampage/TeamPage').then(module => ({
    default: module.TeamPage,
  })),
);
const AboutPage = lazy(() =>
  import('views/about').then(module => ({
    default: module.AboutPage,
  })),
);
const XMSPage = lazy(() =>
  import('views/marketing/XMSpage/XMSPage').then(module => ({
    default: module.XMSPage,
  })),
);
const AnnouncementPage = lazy(() =>
  import('views/marketing/AnnouncementPage').then(module => ({
    default: module.AnnouncementPage,
  })),
);
const PerspectivePage = lazy(() =>
  import('views/marketing/PerspectivePage').then(module => ({
    default: module.PerspectivePage,
  })),
);
const DemosPage = lazy(() =>
  import('views/marketing/DemosPage').then(module => ({
    default: module.DemosPage,
  })),
);
const DemosDetailPage = lazy(() =>
  import('views/marketing/DemosDetailPage').then(module => ({
    default: module.DemosDetailPage,
  })),
);
const MarketersPage = lazy(() =>
  import('views/marketing/MarketersPage').then(module => ({
    default: module.MarketersPage,
  })),
);
const UnsubscribePage = lazy(() =>
  import('views/unsubscribe/UnsubscribePage').then(module => ({
    default: module.UnsubscribePage,
  })),
);
const EmailDestinationConfirmedView = lazy(() =>
  import('views/dashboard/EmailConfirmed/EmailDestinationConfirmedView').then(module => ({
    default: module.EmailDestinationConfirmedView,
  })),
);
const WalletsView = lazy(() =>
  import('views/wallets').then(module => ({
    default: module.WalletsView,
  })),
);

const ContactUsPage = lazy(() =>
  import('views/contact-us').then(module => ({
    default: module.ContactUsPage,
  })),
);

const CaseStudiesPage = lazy(() =>
  import('views/case-studies').then(module => ({
    default: module.CaseStudiesPage,
  })),
);

const NotifiCardPage = lazy(() =>
  import('views/products/NotifiCardpage').then(module => ({
    default: module.NotifiCardPage,
  })),
);

const FoundationAlertPage = lazy(() =>
  import('views/products/FoundationAlertPage').then(module => ({
    default: module.FoundationAlertPage,
  })),
);

const WalletAlertPage = lazy(() =>
  import('views/products/WalletAlertPage').then(module => ({
    default: module.WalletAlertPage,
  })),
);

const ValidatorAlertPage = lazy(() =>
  import('views/products/ValidatorAlertPage').then(module => ({
    default: module.ValidatorAlertPage,
  })),
);

const NotificationServicePage = lazy(() =>
  import('views/products/Notification').then(module => ({
    default: module.NotificationServicePage,
  })),
);

const EventingServicePage = lazy(() =>
  import('views/products/EventingServicePage').then(module => ({
    default: module.EventingServicePage,
  })),
);

const CommunityManagerPage = lazy(() =>
  import('views/products/CommunityManager').then(module => ({
    default: module.CommunityManager,
  })),
);

const MonitoringPage = lazy(() =>
  import('views/products/MonitoringPage').then(module => ({
    default: module.MonitoringPage,
  })),
);

const Router = () => {
  const isMobileStorePageEnabled = useFeatureFlag('isMobileStorePageEnabled');

  return (
    <BrowserRouter basename={'/'}>
      <ScrollToTop />
      <NotistackSnackbarProviderWrapper>
        <Grid
          alignItems='stretch'
          container
          direction='row-reverse'
          flexWrap='nowrap'
          sx={{
            p: 0,
          }}
        >
          <Grid container id='route-wrapper-grid' item>
            <Suspense>
              <Routes>
                <Route element={<LandingPage />} index />
                <Route element={<LandingPage />} path='/*' />
                <Route element={<IntegrationsPage />} path='/integrations' />
                <Route element={<DiscordVerificationPage />} path='/verify_discord' />
                <Route element={<SlackVerificationPage />} path='/verify_slack' />
                <Route element={<TelegramVerificationPage />} path='/tgchannel/:botName' />
                <Route element={<EcosystemsPage />} path='/ecosystem' />
                <Route element={<TeamPage />} path='/team' />
                <Route element={<AboutPage />} path='/about' />
                <Route element={<MarketersPage />} path='/marketers' />
                <Route element={<XMSPage />} path='/xms' />
                <Route element={<HubPage />} path='/individuals' />
                <Route element={<LogoutView />} path='/logout' />
                <Route element={<AnnouncementPage />} path='/blog' />
                <Route element={<PerspectivePage />} path='/perspectives' />
                <Route element={<PressKitPage />} path='/presskit' />
                <Route element={<DemosPage />} path='/demos' />
                <Route element={<DemosDetailPage />} path='/demos/:name' />
                <Route element={<FAQsPage />} path='/faqs' />
                <Route element={<TermsPage />} path='/terms' />
                <Route element={<PrivacyPage />} path='/privacy' />
                <Route element={<CaseStudiesPage />} path='/case-studies' />
                <Route element={<ContactUsPage />} path='/contact-us' />
                <Route element={<NotifiCardPage />} path='/notifi-card' />
                <Route element={<FoundationAlertPage />} path='/foundation-alerts' />
                <Route element={<WalletAlertPage />} path='/wallet-alerts' />
                <Route element={<ValidatorAlertPage />} path='/validator-alerts' />
                <Route element={<NotificationServicePage />} path='/notification-service' />
                <Route element={<EventingServicePage />} path='/eventing-service' />
                <Route element={<CommunityManagerPage />} path='/community-manager' />
                <Route element={<MonitoringPage />} path='/monitoring' />
                <Route element={<EasySubscriptionPage />} path='/easy-subscribe' />
                <Route element={<SmartLinkPage />} path='/smart-link' />
                {isMobileStorePageEnabled && <Route element={<MobileStore />} path='/store' />}
                {/* <Route element={<SignUpView />} path='/signup' /> */}
                {/* <Route element={<EmailConfirmedView />} path='/confirm_email' /> */}
                {/* <Route element={<ResetView />} path='/reset' /> */}
                {/* <Route element={<SetNewPasswordView />} path='/reset_password' /> */}
                <Route element={<EmailDestinationConfirmedView />} path='/verify_target' />
                <Route element={<UnsubscribePage />} path='/unsubscribe' />
                <Route element={<AuthContextProvider />} path='/'>
                  <Route element={<WalletLogin />} path='login' />
                  <Route element={<RequireAuth />} path=''>
                    <Route element={<DashboardView />} path='dashboard' />
                    <Route element={<WalletsView />} path='wallets' />
                    <Route element={<DappSubscriptionsView />} path='dappsubscriptions' />
                    <Route element={<ChatView />} path='chat/*' />
                    <Route element={<CreatorView />} path='creator' />
                    <Route element={<ConnectWalletStepperContainer />} path='connect' />
                    <Route element={<SettingsView />} path='settings' />
                  </Route>
                </Route>
                <Route element={<RedirectPage />} path='fooRedirect' />
              </Routes>
            </Suspense>
          </Grid>
        </Grid>
      </NotistackSnackbarProviderWrapper>
    </BrowserRouter>
  );
};

export default Router;
