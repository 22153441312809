import { AppBar, Box, Button, Drawer, Grid, IconButton, Stack, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from 'components/footer';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { eggshell, midnight, white } from 'theme/Theme';

import { ReactComponent as MenuIcon } from '../../assets/btn-menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/btn-x.svg';
import { ReactComponent as Logo } from '../../assets/notifi_logo.svg';
import { TopNavButtonList } from './TopNavButtonList';

export type ActiveTab =
  | 'Developers'
  | 'Marketers'
  | 'Individuals'
  | 'Team'
  | 'Resources'
  | 'Ecosystem'
  | 'Products'
  | 'About'
  | 'CaseStudies'
  | 'xyz';

type Props = {
  activeTab?: ActiveTab;
};

const TopNav = ({ activeTab }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [isShow, setIsShow] = useState(false);

  const [openProducts, setOpenProducts] = React.useState(false);
  const [openResources, setOpenResources] = React.useState(false);
  const [isStudiesOpen, setIsStudiesOpen] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [openMenuItemMobile, setOpenMenuItemMobile] = React.useState<boolean>(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleToggleProducts = () => {
    setOpenProducts(prevOpen => !prevOpen);
  };

  const handleToggleResources = () => {
    setOpenResources(prevOpen => !prevOpen);
  };

  const handleToggleStudies = () => {
    setIsStudiesOpen(prevOpen => !prevOpen);
  };

  const handleMenuButtonClick = () => {
    setMobileMenuOpen(prevMobileMenuOpen => !prevMobileMenuOpen);
    setOpenMenuItemMobile(false);
  };

  const handleScroll = () => {
    setIsShow(window.pageYOffset > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isHomePage = useMemo(() => {
    return location.key === 'default' || location.pathname === '/main' || location.pathname === '/';
  }, [location]);

  return (
    <Box sx={{ position: 'sticky', top: 0, width: '100%', zIndex: 1350 }}>
      {isMobile ? (
        <Box sx={{ height: '50px' }}>
          <AppBar
            position='fixed'
            sx={{
              backgroundColor: white,
              boxShadow: 'none',
              zIndex: theme => theme.zIndex.drawer + 1,
            }}
          >
            <Toolbar
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <IconButton
                aria-label='menu'
                color='inherit'
                edge='start'
                onClick={handleMenuButtonClick}
                sx={{ left: '20px', mr: 2, position: 'absolute' }}
              >
                {mobileMenuOpen ? <CloseIcon /> : <MenuIcon height={17} width={25} />}
              </IconButton>
              <Logo onClick={() => navigate('/')} width={85} />
            </Toolbar>
          </AppBar>
          <Drawer
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            anchor='top'
            open={mobileMenuOpen}
            sx={{
              '& .MuiDrawer-paper': {
                backgroundColor: eggshell,
                height: '100vh',
                position: 'relative',
                pt: '100px',
              },
            }}
            variant='temporary'
          >
            <Stack direction='column' justifyContent='space-between' sx={{ height: '100%' }}>
              <div>
                <Stack
                  alignItems='start'
                  direction='column'
                  sx={{ backgroundColor: eggshell, width: '100%' }}
                >
                  <TopNavButtonList
                    activeTab={activeTab}
                    mobileMenu={true}
                    openMenuItemMobile={openMenuItemMobile}
                    setMobileMenuOpen={setMobileMenuOpen}
                    setOpenMenuItemMobile={setOpenMenuItemMobile}
                  />
                </Stack>
              </div>
              <Footer />
            </Stack>
          </Drawer>
        </Box>
      ) : (
        <Grid
          alignItems='center'
          columnGap={4}
          container
          flexShrink={0}
          flexWrap='nowrap'
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            backgroundColor: !isHomePage || isShow ? white : 'transparent',
            msOverflowStyle: 'none',
            overflowX: 'auto',
            overflowY: 'hidden',
            position: 'static',
            px: 8,
            py: 1,
            scrollbarWidth: 'none',
            zIndex: 2,
          }}
        >
          <Grid item>
            <Link to='/main'>
              <Logo width={120} />
            </Link>
          </Grid>
          <TopNavButtonList
            activeTab={activeTab}
            handleToggle={handleToggle}
            handleToggleProducts={handleToggleProducts}
            handleToggleResources={handleToggleResources}
            handleToggleStudies={handleToggleStudies}
            isOpenMenu={open}
            isStudiesOpen={isStudiesOpen}
            mobileMenu={false}
            openProducts={openProducts}
            openResources={openResources}
            setIsStudiesOpen={setIsStudiesOpen}
            setOpenMenu={setOpen}
            setOpenProducts={setOpenProducts}
            setOpenResources={setOpenResources}
          />

          {/* <TopNavButton isActive={false}
        onClick={() => window.open(API_DOCS_URL, '_blank')}>
              Docs
      </TopNavButton> */}

          <Box sx={{ flex: '1 1 auto' }} />

          {/* <SocialMediaLinks justifyContent='flex-end' /> */}

          {/* {!isAuthenticated && (
        <Grid item>
          <Button
            disableFocusRipple={false}
            onClick={() => navigate('/signup')}
            size='small'
            sx={{
              border: `1px solid ${coral}`,
              width: 150,
            }}
            variant='outlined'
          >
            Create Account
          </Button>
        </Grid>
      )}

      {!isAuthenticated && (
        <Grid item>
          <Button
            color='primary'
            onClick={() => navigate('/login')}
            size='small'
            sx={{
              width: 89,
            }}
            variant='contained'
          >
            Sign In
          </Button>
        </Grid>
      )} */}
          <Grid
            item
            sx={{
              marginRight: '101px',
            }}
          >
            <Button
              onClick={() => navigate('/dashboard')}
              size='small'
              sx={{
                borderColor: midnight,
                borderRadius: '30px',
                boxShadow: 'none !important',
                color: midnight,
                width: 110,
              }}
              variant='outlined'
            >
              Notifi Hub
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TopNav;
