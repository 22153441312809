import { Box, Container, Grid, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { dusk, eggshell, midnight } from 'theme/Theme';
import noop from 'utils/noop';

type SectionProps = {
  title: string;
};
export const Section: React.FC<React.PropsWithChildren<SectionProps>> = props => {
  const { children, title } = props;

  return (
    <Container disableGutters id={title} maxWidth={false}>
      <Container disableGutters maxWidth={false}>
        {children}
      </Container>
    </Container>
  );
};

type BackgroundProps = {
  style: SxProps;
};
export const BackgroundContainer: React.FC<React.PropsWithChildren<BackgroundProps>> = ({
  children,
  style,
}) => {
  const maxWidth = 1200;
  const px = '8vw';

  return (
    <Container disableGutters maxWidth={false} sx={{ ...style, px, width: '100%' }}>
      <Container sx={{ maxWidth, position: 'absolute', width: '100%' }}>{children}</Container>
    </Container>
  );
};

type LogoProps = {
  altText: string;
  imgSrc: string;
  sx?: SxProps;
  onClick?: () => void;
  mb?: number;
};
export const BrandLogo = ({ altText, imgSrc, mb = 0, onClick = noop, sx = {} }: LogoProps) => {
  return (
    <Grid
      item
      sx={{
        mb: { sm: 0, xs: mb },
        overflow: 'clip',
        textAlign: 'center',
        width: 'fit-content',
        ...sx,
      }}
    >
      <img
        alt={altText}
        onClick={onClick}
        src={imgSrc}
        style={{
          height: '100%',
          objectFit: 'contain',
          width: '100%',
        }}
        width='100%'
      />
    </Grid>
  );
};

type CardProps = {
  body: string;
  icon: string;
  title: string;
};
export const Card = ({ body, icon, title }: CardProps) => {
  return (
    <Grid
      item
      sx={{
        backgroundColor: 'white',
        borderRadius: '15px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
        my: 3,
        overflow: 'hidden',
        p: 3,
        width: 280,
      }}
    >
      <div style={{ marginBottom: '12px', marginTop: '4px', textAlign: 'center' }}>
        <img alt='card-icon' src={icon} />
      </div>
      <Typography
        align='center'
        gutterBottom
        sx={{
          color: midnight,
          fontFamily: 'RotaExtraBold',
          fontSize: 'min(max(22px, 3vw), 26px)',
          fontWeight: 800,
        }}
      >
        {title}
      </Typography>
      <Typography
        align='center'
        sx={{
          color: dusk,
          fontFamily: 'NeueMontrealMedium',
          fontSize: 'min(max(14px, 3vw), 18px)',
          fontWeight: 500,
          lineHeight: '26px',
        }}
      >
        {body}
      </Typography>
    </Grid>
  );
};

export const Header = ({
  header,
  headerColor = midnight,
  headerSx,
}: {
  header: string;
  headerColor?: string;
  headerSx?: SxProps;
}) => {
  return (
    <Typography
      align='center'
      sx={{
        color: headerColor,
        fontFamily: 'RotaExtraBold',
        fontSize: { sm: '35px', xs: '22px' },
        fontWeight: 800,
        lineHeight: { sm: '45px', xs: '28px' },
        mb: { sm: 2, xs: '4px' },
        ...headerSx,
      }}
    >
      {header}
    </Typography>
  );
};

type TextWithBottomBorderProps = {
  label: string;
  typographySx?: SxProps;
};

export const TextWithBottomBorder: React.FC<TextWithBottomBorderProps> = ({
  label,
  typographySx,
}) => {
  return (
    <Typography
      align='center'
      sx={{
        color: dusk,
        fontFamily: 'RotaExtraBold',
        fontSize: { sm: '18px', xs: '12px' },
        lineHeight: '20px',
        m: '0 auto',
        pb: 1,
        pt: '3px',
        width: '100%',
        ...typographySx,
      }}
      variant='h4'
    >
      {label}
      <Box sx={{ background: eggshell, height: '4px', mt: '37px', mx: 'auto', width: '346px' }} />
    </Typography>
  );
};

export const TextPairing = ({
  header,
  headerColor = midnight,
  headerSx = {},
  subheader,
  subheaderColor = midnight,
  sx = {},
}: {
  header: string;
  headerColor?: string;
  headerSx?: SxProps;
  subheader: string;
  subheaderColor?: string;
  sx?: SxProps;
}) => {
  return (
    <Stack alignItems='center' justifyContent='center' sx={sx}>
      <Header header={header} headerColor={headerColor} headerSx={headerSx} />
      <Typography
        align='center'
        sx={{
          color: subheaderColor,
          fontFamily: 'Rota',
          fontSize: { sm: '22px', xs: '16px' },
          fontWeight: 600,
          lineHeight: { sm: '32px', xs: '22px' },
          mt: { sm: 0, xs: '12px' },
        }}
      >
        {subheader}
      </Typography>
    </Stack>
  );
};
