import { Container, Stack, Typography } from '@mui/material';
import { coral, dusk, icyGradient2, midnight } from 'theme/Theme';

import SmartLinkImg from '../../../assets/img-smartlink-banner.svg';

const SmartLinkHeroSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        background: icyGradient2,
        pb: { lg: 10, xs: 0 },
        pt: { sm: 10, xs: '27px' },
        px: 2,
      }}
    >
      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        justifyContent='space-between'
        sx={{ alignItems: 'center', maxWidth: 1200, mx: 'auto' }}
      >
        <Stack sx={{ maxWidth: '720px' }}>
          <Typography
            sx={{ color: coral, fontFamily: 'RotaExtraBold', fontSize: '18px', mb: '10px' }}
          >
            Smart Links
          </Typography>
          <Typography
            sx={{
              color: midnight,
              fontFamily: 'RotaExtraBold',
              fontSize: { sm: '50px', xs: '30px' },
              mb: '20px',
              width: { sm: '100%', xs: '100%' },
            }}
          >
            A new way for users to interact with blockchain technology
          </Typography>
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'RotaSemiBold',
              fontSize: { md: '22px', xs: '20px' },
              maxWidth: '570px',
            }}
          >
            Smart Links offer easily configurable UI components, allowing developers and businesses
            to integrate blockchain interactions seamlessly into their platforms.
          </Typography>
        </Stack>
        <Stack sx={{ pt: { md: 0, xs: '30px' } }}>
          <img alt='notifi-card' src={SmartLinkImg} />
        </Stack>
      </Stack>
    </Container>
  );
};

export default SmartLinkHeroSection;
