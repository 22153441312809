import { Box, Container, Stack, Typography } from '@mui/material';
import { dusk, midnight } from 'theme/Theme';

import SmartLinkDemoCardImg from '../../../assets/smart-link-demo-card.png';
import SmartLinkPreviewImg from '../../../assets/smart-link-preview.png';

const SmartLinkInfoSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: 1250,
        px: 2,
        py: { sm: 9, xs: '40px' },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        sx={{ alignItems: 'center', justifyContent: 'space-between', rowGap: '30px' }}
      >
        <Stack sx={{ maxWidth: '460px' }}>
          <Typography
            sx={{ color: midnight, fontFamily: 'RotaExtraBold', fontSize: '32px', mb: '16px' }}
          >
            Easily configurable transactions and UI components
          </Typography>
          <Typography sx={{ color: dusk, fontFamily: 'RotaSemiBold', fontSize: '18px' }}>
            Smart Link Actions will leverage the Notifi Fusion system so that there is ultimate
            flexibility in executing anything from a simple response with an encoded transaction to
            execute, to more complex Actions where on/off-chain API’s can be called within the
            action. Whether it’s sending payments, signing agreements, or triggering smart
            contracts, Notifi Admin users can create and share personalized blockchain actions with
            ease.
          </Typography>
        </Stack>
        <Box sx={{ maxWidth: '425px' }}>
          <img
            alt='blockchains'
            src={SmartLinkPreviewImg}
            style={{ height: 'auto', width: '100%' }}
          />
        </Box>
      </Stack>

      <Stack
        direction={{ md: 'row-reverse', xs: 'column' }}
        sx={{ alignItems: 'center', justifyContent: 'space-between', pt: 2, rowGap: '30px' }}
      >
        <Stack sx={{ maxWidth: '460px' }}>
          <Typography
            sx={{ color: midnight, fontFamily: 'RotaExtraBold', fontSize: '32px', mb: '16px' }}
          >
            Clear interface for users
          </Typography>
          <Typography sx={{ color: dusk, fontFamily: 'RotaSemiBold', fontSize: '18px' }}>
            Designed with simplicity and flexibility in mind, Smart Links offer easily configurable
            UI components, allowing developers and businesses to integrate blockchain interactions
            seamlessly into their platforms. Users are greeted with a clear, intuitive interface,
            making it effortless to navigate and execute actions with just a single click.
          </Typography>
        </Stack>
        <Box
          sx={{
            marginLeft: {
              lg: '-50px',
              xs: 0,
            },
            maxWidth: '600px',
          }}
        >
          <img
            alt='uptime'
            src={SmartLinkDemoCardImg}
            style={{
              height: 'auto',
              width: '100%',
            }}
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default SmartLinkInfoSection;
